import React from 'react';

const DQ = () => {

    // Show the modal when the page loads

  return (
    <div className="flex px-2 justify-center h-screen py-4">
  <style>
    {`
      .shadow-custom {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
      }

      .animate-fade-in {
        animation: fade-in 0.5s ease-in-out;
      }
      @keyframes fade-in {
        0% { opacity: 0; transform: translateY(10px); }
        100% { opacity: 1; transform: translateY(0); }
      }
    `}
  </style>

  <div className="w-full bg-white shadow-top-prominent rounded-3xl">
    <nav
      className="w-full bg-white px-2 flex justify-between items-center"
      style={{
        backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex items-center">
        <img
          src="https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cf3_new%20Benmore.svg"
          alt="Benmore Technologies"
          className="h-8 md:h-12"
        />
      </div>
    </nav>

    <div className="space-y-6 p-6 pt-0">
      <div
        className="text-center"
        style={{
          backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h1 className="font-extrabold pt-6 text-2xl md:text-5xl md:px-[10%]">
          Thank You for Your Interest in <span className="text-[#1D54E2]">Benmore Technologies</span>
        </h1>

        <h2 className="mt-4 text-lg md:text-2xl">
          We'll be in touch soon.
        </h2>
      </div>

      <div className="md:px-[10%] text-center">
        {/* <p className="my-4 text-lg md:text-2xl">
          We appreciate your submission, but based on your responses, you do not meet the requirements for a free discovery call. However, we'd love to stay in touch and help you when the time is right.
        </p> */}

        {/* <p className="mt-6 text-md md:text-lg">
          Please feel free to explore our resources and reach out when you're ready to move forward with your software project. We're always here to assist you.
        </p>

        <a
          href="https://www.benmore.tech/"
          className="inline-block mt-6 py-3 px-6 text-white font-bold rounded-lg bg-[#2E5AF4]"
        >
          Explore Resources
        </a> */}
      </div>
    </div>
  </div>
</div>

  );
};

export default DQ;
